.emergency-services {
  background: url('../images/emergency-services-bg.jpg') no-repeat center center;
  background-size: cover;
  width: 100vw;
  position: relative;
  padding: 0 30px;
  margin: -17px 0 1px -25px;

  @media (min-width: $medium) {
    height: 80px;
  }

  @media (min-width: $large) {
    margin: auto;
    padding: 0 50px;
  }

  h2 {
    position: relative;
    z-index: 1;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .01em;
    font-family: $proxima;
    line-height: 40px;
    padding: 10px 0;
    margin: 0;

    @media (min-width: $medium) {
      line-height: 80px;
      padding: 0;
    }

    .yellow-text {
      display: block;
      color: $yellowsea;
      font-family: $lexiaadvert;
      font-weight: 500;
      font-size: 40px;
      vertical-align: -6px;
      letter-spacing: 0;
      margin-right: 10px;

      @media (min-width: $medium) {
        display: inline;
      }
    }
  }

  .fa-angle-right {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    color: #ffffff;
    right: 30px;
    font-size: 30px;
    font-weight: 800;

    @media (min-width: $large) {
      right: 50px;
    }
  }

  &:hover {
    cursor: pointer;

    a {
      text-decoration: none;
    }

    &:after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, .5);
      width: 100vw;
      height: 100px;
      left: 0;
      top: 0;
      z-index: 0;

      @media (min-width: $medium) {
        height: 80px;
      }
    }

    .fa-angle-right {
      color: $yellowsea;
    }
  }
}