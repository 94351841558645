$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors
$primary-color: #007f85;
$secondary-color: #f0af00;
$palettes: (
  base: (primary: $primary-color, secondary: $secondary-color),
  footer: (light-bg: #f7f7f7, light-heading: #404040, light-copy: #666666, dark-bg: #595959, dark-copy: #fff),
  elements: (heading: #000, text: #404040)
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

// usage background: palette(grays, 1);

// colors
$teal: #007f85;
$bluechill: #097e88;
$hippieblue: #4d9aa1;
$hippieblue2: #509ea4;
$surfiegreen: #0a7680;
$deepseagreen: #064d53;
$atoll: #086b74;
$neptune: #72afb5;
$glacier: #84bec3;
$ziggurat: #c1dfe1;
$botticelli: #cee5e7;
$yellowsea: #f0af00;
$alabaster: #f7f7f7;
$alabaster2: #fafafa;
$concrete: #f2f2f2;
$alto: #d9d9d9;
$alto2: #dedede;
$tundora: #404040;
$tundora2: #4d4d4d;
$tundora3: #454545;
$tundora4: #414141;
$dovegray: #666666;
$dovegray2: #737373;
$nobel: #b3b3b3;
$mercury: #e6e6e6;
$silver: #cccccc;
$scorpion: #595959;
$dustygray: #999999;
$mineshaft: #333333;
$stiletto: #a63232;

// fonts
$proxima: 'Fira Sans', sans-serif;
$proximacon: 'Fira Sans Condensed', sans-serif;
$proximaextracon: 'Fira Sans Extra Condensed', sans-serif;
$lexiaadvert: 'Archivo Black', sans-serif;
$lexia: 'Sanchez', serif;