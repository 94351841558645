/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.element-invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-for-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-for-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-for-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-for-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-for-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-for-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-for-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-for-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-for-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-for-medium-only {
    display: none;
  }
}
@media (min-width: 1180px) {
  .show-for-medium-only {
    display: none;
  }
}

.show-for-large-only {
  display: none;
}
@media (min-width: 1180px) and (max-width: 1340px) {
  .show-for-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-for-large-only {
    display: none;
  }
}

.show-for-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-for-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-for-xlarge-only {
    display: none;
  }
}

.show-for-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-for-xxlarge-only {
    display: none;
  }
}

.show-for-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-for-medium-up {
    display: block;
  }
}

.show-for-large-up {
  display: none;
}
@media (min-width: 1180px) {
  .show-for-large-up {
    display: block;
  }
}

.show-for-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-for-xlarge-up {
    display: block;
  }
}

.show-for-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-for-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-for-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1179px) {
  .hide-for-medium-only {
    display: none;
  }
}

@media (min-width: 1180px) and (max-width: 1340px) {
  .hide-for-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-for-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-for-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-for-medium-up {
    display: none;
  }
}

@media (min-width: 1180px) {
  .hide-for-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-for-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-for-xxlarge-up {
    display: none;
  }
}

.emergency-services {
  background: url("../images/emergency-services-bg.jpg") no-repeat center center;
  background-size: cover;
  width: 100vw;
  position: relative;
  padding: 0 30px;
  margin: -17px 0 1px -25px;
}
@media (min-width: 640px) {
  .emergency-services {
    height: 80px;
  }
}
@media (min-width: 1180px) {
  .emergency-services {
    margin: auto;
    padding: 0 50px;
  }
}
.emergency-services h2 {
  position: relative;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.01em;
  font-family: "Fira Sans", sans-serif;
  line-height: 40px;
  padding: 10px 0;
  margin: 0;
}
@media (min-width: 640px) {
  .emergency-services h2 {
    line-height: 80px;
    padding: 0;
  }
}
.emergency-services h2 .yellow-text {
  display: block;
  color: #f0af00;
  font-family: "Archivo Black", sans-serif;
  font-weight: 500;
  font-size: 40px;
  vertical-align: -6px;
  letter-spacing: 0;
  margin-right: 10px;
}
@media (min-width: 640px) {
  .emergency-services h2 .yellow-text {
    display: inline;
  }
}
.emergency-services .fa-angle-right {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  color: #ffffff;
  right: 30px;
  font-size: 30px;
  font-weight: 800;
}
@media (min-width: 1180px) {
  .emergency-services .fa-angle-right {
    right: 50px;
  }
}
.emergency-services:hover {
  cursor: pointer;
}
.emergency-services:hover a {
  text-decoration: none;
}
.emergency-services:hover:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100px;
  left: 0;
  top: 0;
  z-index: 0;
}
@media (min-width: 640px) {
  .emergency-services:hover:after {
    height: 80px;
  }
}
.emergency-services:hover .fa-angle-right {
  color: #f0af00;
}